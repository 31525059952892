<template>
  <div id="AlikeScore">
    <div class="search">输入综合分：
      <el-input class="input" v-model="kewWord" size="mini"></el-input>
      <el-button type="primary" class="btn" size="mini" @click="getList" round>立即查询</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData1" border style="width: 100%" size="medium" stripe :header-cell-style="rowClass">
        <el-table-column prop="name" label="学校名称" show-overflow-tooltip width="260">
          <template slot-scope="scope">
            {{ scope.row.name }}
            <span class="remarks" v-if="scope.row.batch == 5">原A</span>
            <span class="remarks" v-if="scope.row.batch == 6">原B</span>
          </template>
        </el-table-column>
        <el-table-column prop="min_score" label="2023投档最低分">
        </el-table-column>
      </el-table>
    </div>
    <div class="table">
      <el-table :data="tableData2" border style="width: 100%" size="medium" stripe :header-cell-style="rowClass">
        <el-table-column prop="name" label="学校名称" show-overflow-tooltip width="260">
          <template slot-scope="scope">
            {{ scope.row.name }}
            <span class="remarks" v-if="scope.row.batch == 5">原A</span>
            <span class="remarks" v-if="scope.row.batch == 6">原B</span>
          </template>
        </el-table-column>
        <el-table-column prop="min_score" label="2023投档最低分">
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
export default {

  name: '',
  data() {
    return {
      kewWord: '',
      tableData1: [],
      tableData2: [],
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  props: ['art_batch'],
  methods: {
    rowClass() {
      return 'background:#Fafafa'
    },
    getList() {
      let data = {
        score: this.kewWord,
        is_wenli: this.userInfo.is_wenli,
        art_batch: this.art_batch
      }
      this.$fecth.post('art_school_score/getCollegeList', data).then((res) => {
        let key = Math.ceil(res.length / 2)
        this.tableData2 = res.splice(key, key)
        this.tableData1 = res

      })
    }
  },
};
</script>

<style lang='less'>
#AlikeScore {
  .search {
    padding: 10px;

    .input {
      display: inline-block;
      width: 130px;

    }

    .el-input__inner {
      border-radius: 30px;
    }
  }

  .btn {
    margin-left: 13px;
  }

  .table {
    width: 600px;
    display: inline-block;
    vertical-align: top;
  }
}

.remarks {
  font-size: 10px;
  color: red;
}
</style>
