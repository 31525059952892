<template>
  <div id="CulturalScore">
    <div class="search">输入院校名称：
      <el-select size="mini" v-model="kewWord" filterable remote reserve-keyword placeholder="请输入关键词"
        :remote-method="remoteMethod" :loading="loading">
        <el-option v-for="item in options" :key="item.cid" :label="item.name" :value="item.cid">
        </el-option>
      </el-select>

      <el-button type="primary" class="btn" size="mini" @click="getList" round>立即查询</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData" border style="width: 100%" size="medium" stripe :span-method="arraySpanMethod">
        <el-table-column prop="name" label="学校名称" show-overflow-tooltip width="260">
        </el-table-column>
        <el-table-column prop="specialty_name" label="专业信息">
          <template slot-scope="scope">
            <div> [{{ scope.row.specialty_id | interpo }}] {{ scope.row.specialty_name }} </div>
          </template>
        </el-table-column>
        <el-table-column label="测算文化分" width="100">
          <template slot-scope="scope">
            <div> {{ score(scope.row) }} </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: '',
  props: ['art_batch', 'oldScoreArt'],
  data() {
    return {
      // kewWord: 663,
      kewWord: '',
      tableData: [],
      options: [{
        cid: '',
        name: ''
      }],
      loading: false
    };
  },
  watch: {
    art_batch() {
      this.getList()
    }
  },
  computed: {
    ...mapState(["userInfo"]),
    score() {
      return function (item) {
        if (this.oldScoreArt) {
          if (item.w_scale != 0) {
            return ((item.min_score - this.oldScoreArt[2] * item.z_scale / 100) / item.w_scale * 100).toFixed(0)
          } else {
            return '文过专排'
          }

        }
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    remoteMethod(item) {
      if (!item) return;
      let data = {
        name: item,
        is_wenli: this.userInfo.is_wenli,
        art_batch: this.art_batch
      }
      this.loading = true
      this.$fecth.post('art_specialty_score/getCollegeNameList', data).then((res) => {
        this.options = res
        this.loading = false
      })
    },
    getList() {
      let data = {
        is_wenli: this.userInfo.is_wenli,
        art_batch: this.art_batch,
        cid: this.kewWord
      }
      this.$fecth.post('art_specialty_score/getSpecialty', data).then((res) => {
        this.tableData = res
      })
    },
    arraySpanMethod(item) {
      let len = this.tableData.length
      if (item.columnIndex === 0) {
        if (item.rowIndex === 0) {
          return [len, 1];
        } else {
          return [0, 0];
        }
      }
    },
  },
};
</script>

<style lang='less'>
#CulturalScore {
  .search {
    padding: 10px;

    .input {
      display: inline-block;
      width: 130px;

    }

    .el-input__inner {
      border-radius: 30px;
    }
  }

  .btn {
    margin-left: 13px;
  }

}
</style>
