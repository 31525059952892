<template>
  <div>
    <div class="main">
      <div class="title">综合分测算器 <span class="remarks">根据输入的专业分和文化分自动算出各种规则下的综合分，并可查看使用该规则的院校列表。</span> </div>
    </div>

    <comprehensive-score :art_batch="art_batch"></comprehensive-score>

    <div class="main">
      <div class="title">同分去向</div>
      <div class="remarks">（根据下方输入的综合分成绩，可查询上年取得该综合分成绩考生被录取的院校。
        ）</div>
    </div>
    <alike-score :art_batch="art_batch"></alike-score>
    <div class="main" style="margin-top: 20px;margin-bottom: 20px;"> <span class="remarks">备注：以上查询功能仅供参考，以实际录取为准。</span> </div>

    <div class="main">
      <div class="title">文化分测算器</div>
      <div class="remarks">（根据下方输入的院校名称，系统自动依据专业课成绩，计算出达到该院校/专业的文化课成绩需要多少分。）</div>
    </div>
    <cultural-score :art_batch="art_batch" :oldScoreArt="oldScoreArt"></cultural-score>
    <div class="main" style="margin-top: 20px;margin-bottom: 20px;"> <span class="remarks">备注：以上测算功能结果仅供参考使用，具体所需成绩以当年录取为准。</span> </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
//综合分测算器
import comprehensiveScore from "./components/Comprehensive";
import AlikeScore from "./components/AlikeScore";
import CulturalScore from "./components/CulturalScore";

export default {
  name: '',
  props: [
    'art_batch', 'oldScoreArt'
  ],
  data() {
    return {

    };
  },

  components: {
    comprehensiveScore,
    AlikeScore,
    CulturalScore
  },
  mounted() {
    this.$emit("Nav", 1);
  },
  computed: {
    ...mapState(["userInfo"]),
  },
};
</script>

<style scoped lang='less'>
.main {
  .title {
    border-left: 5px solid #519cea;
    text-indent: 0.5rem;
    margin: 20px 0;
    font-size: 18px;
    color: #4d4d4d;
    display: inline-block;
  }

  .remarks {
    display: inline-block;
    font-size: 12px;
    color: grey;
  }

}
</style>
