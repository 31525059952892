<template>
  <div>
    <div :class="{ table: data2.length != 0, }">
      <el-table :data="data1" :header-cell-style="rowClass" border style="width: 100%;margin-bottom: 40px;"
        size="medium ">
        <el-table-column type="index" align="center" label="序号" width="60">
          <template slot-scope="scope">
            <span class="indexBg">{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="lqgz" label="" min-width="220">
          <template slot="header">
            录取规则 <span style="color: red;">（2024）</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="综合分" min-width="60">
          <template slot-scope="scope">
            {{ (userInfo.score * scope.row.w_scale + userInfo.z_score * scope.row.z_scale).toFixed(3) }}
          </template>

        </el-table-column>
        <el-table-column prop="college_num" label="查看院校/专业">
          <template slot-scope="scope">
            <div @click="handleClick(scope.row)" type="text" size="small">查看院校<span style="color: red;">{{
      scope.row.college_num }}</span>所</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table" v-if="data2.length != 0">
      <el-table :data="data2" border style="width: 100%;margin-bottom: 40px;" :header-cell-style="rowClass"
        size="medium ">
        <el-table-column type="index" align="center" label="序号" width="60">
          <template slot-scope="scope">
            <span class="indexBg">{{ scope.$index + 1 + data1.length }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="lqgz" label="" min-width="220">
          <template slot="header">
            录取规则 <span style="color: red;">（2024）</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="综合分" min-width="60">
          <template slot-scope="scope">
            {{ (userInfo.score * scope.row.w_scale + userInfo.z_score * scope.row.z_scale).toFixed(3) }}
          </template>
        </el-table-column>
        <el-table-column prop="college_num" label="查看院校/专业">
          <template slot-scope="scope">
            <div @click="handleClick(scope.row)" type="text" size="small">查看院校<span style="color: red;">{{
      scope.row.college_num }}</span>所</div>
          </template>
        </el-table-column>
      </el-table>

    </div>


    <br>


    <!-- <div :class="{ table: tableData2.length != 0, }">
      <el-table :data="tableData1" :header-cell-style="rowClass" border style="width: 100%" size="medium ">
        <el-table-column type="index" align="center" label="序号" width="60">
          <template slot-scope="scope">
            <span class="indexBg">{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="lqgz" label="录取规则" min-width="220">
        </el-table-column>
        <el-table-column prop="" label="综合分" min-width="60">
          <template slot-scope="scope">
            {{ (userInfo.score * scope.row.w_scale + userInfo.z_score * scope.row.z_scale).toFixed(3) }}
          </template>

        </el-table-column>
        <el-table-column prop="college_num" label="查看院校/专业">
          <template slot-scope="scope">
            <div @click="handleClick(scope.row)" type="text" size="small">查看院校<span style="color: red;">{{
      scope.row.college_num }}</span>所</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table" v-if="tableData2.length != 0">
      <el-table :data="tableData2" border style="width: 100%" :header-cell-style="rowClass" size="medium ">
        <el-table-column type="index" align="center" label="序号" width="60">
          <template slot-scope="scope">
            <span class="indexBg">{{ scope.$index + 1 + tableData1.length }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="lqgz" label="录取规则" min-width="220">
        </el-table-column>
        <el-table-column prop="" label="综合分" min-width="60">
          <template slot-scope="scope">
            {{ (userInfo.score * scope.row.w_scale + userInfo.z_score * scope.row.z_scale).toFixed(3) }}
          </template>
        </el-table-column>
        <el-table-column prop="college_num" label="查看院校/专业">
          <template slot-scope="scope">
            <div @click="handleClick(scope.row)" type="text" size="small">查看院校<span style="color: red;">{{
      scope.row.college_num }}</span>所</div>
          </template>
        </el-table-column>
      </el-table>

    </div> -->

    <!-- <div class="btn">
      <el-button round size="mini" type="primary">一键测算</el-button>
    </div> -->

    <el-dialog title="查看院校" :visible.sync="dialogTableVisible" width="1200px" destroy-on-close>
      <div style="border-bottom:  1px solid #dfdfdf;"></div>
      <CollegeList v-for="(item, i) in CollegeListData" :key="i" :item="item"></CollegeList>
      <div class="pagination">
        <el-pagination :hide-on-single-page="true" :page-size="pageSize" :current-page="pageNum" :pager-count="11"
          :total="total" class="page-box" layout="prev, pager, next" @current-change="pageChange" />
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { mapState } from "vuex";
import CollegeList from './CollegeList.vue'
export default {
  name: '',
  props: [
    "art_batch"
  ],
  components: {
    CollegeList
  },
  data() {
    return {
      tableData1: [],
      tableData2: [],
      data1: [],
      data2: [],
      CollegeListData: [],
      loading: true,
      dialogTableVisible: false,
      total: 0,
      pageNum: 1,
      pageSize: 10,
      lqgz_id: ''
    };
  },
  watch: {
    art_batch() {
      this.getArtNewCollegeNum()
    }
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  mounted() {
    this.getArtNewCollegeNum()
  },
  methods: {
    getArtNewCollegeNum() {
      let data = {
        is_wenli: this.userInfo.is_wenli,
        art_batch: this.art_batch
      }
      this.$fecth.post('art_school/getArtNewCollegeNum', data).then((res) => {
        let key = Math.ceil(res.length / 2)
        this.data2 = res.splice(key, key)
        this.data1 = res
      })
    },
    // 表格样式设置
    rowClass() {
      return 'background:#Fafafa'
    },

    handleClick(item) {
      this.lqgz_id = item.lqgz_id
      this.dialogTableVisible = true;
      this.pageNum = 1
      this.getCollegeList()

    },

    pageChange(page) {
      document.body.scrollTop = document.documentElement.scrollTop = 650
      this.pageNum = page
      let data = {
        lqgz_id: this.lqgz_id
      }
      this.getCollegeList(data)
    },

    getCollegeList() {
      let data = {
        is_wenli: this.userInfo.is_wenli,
        art_batch: this.art_batch,
        lqgz_id: this.lqgz_id
      }
      data.pageNum = this.pageNum
      data.pageSize = this.pageSize
      this.$fecth.post('art_school/getSchoolList', data).then((res) => {
        this.CollegeListData = res.lists
        this.total = res.total
      })
    }

  },
};
</script>

<style scoped lang='less'>
.indexBg {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 40px;
  background: #409EFF;
  color: white;
}

.table {
  width: 600px;
  display: inline-block;
  vertical-align: top;
}

.table1 {
  width: 1200px;
  display: inline-block;
  vertical-align: top;
}

.btn {
  text-align: right;
  margin-top: 20px;
}

.pagination {
  padding: 40px 0;

  .page-box {
    text-align: center;
  }
}
</style>
